.cardImgContainer {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.cardImgContainer:hover .overlay {
  opacity: 1;
}

.cardImg {
  border-radius: 10px;
  width: 100%;
  max-width: 305px;
}

.overlay {
  display: flex;
  justify-content: center;
  align-items: center;

  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: hsla(178, 100%, 50%, 0.4);
}
